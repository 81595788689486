import { useState, createContext } from 'react'

export const BaseConfigContext = createContext({})

//空白画布的初始数据
const BaseConfig = ({ children }) => {
    const [maxSize, setMaxSize] = useState({
        width: 375,
        height: 667,
    })
    return (
        <BaseConfigContext.Provider
            value={{
                maxSize,
                changeMaxSize: (newSize) => {
                    const result = Object.assign({}, maxSize, newSize)
                    setMaxSize(result)
                }
            }}
        >
            {children}
        </BaseConfigContext.Provider>
    )
}

export default BaseConfig