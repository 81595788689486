import React, { useContext } from 'react'
import './index.less'

import { DrawPageStateContext, HotAreaConfigContext } from '@/context'
export default function HotAreaHoc({
    editContentSize,
    editContent,
    contentFit = 'contain',
    defaultValue = [],
    onSave,
    onCopy,
    style = {},
    showCopyBtn = true
}) {
    const { setShowHotEdit } = useContext(DrawPageStateContext)
    const { setContentSize, setContent, setSaveCb, setDefaultValue, setContentFit } = useContext(HotAreaConfigContext)
    const wrapStyle = Object.assign({ width: 320, height: 162 }, style)
    return (
        <div style={wrapStyle} className="hot-area-hoc items-center justify-center flex-col">
            <div>编辑热区</div>
            <div
                className="items-center"
            >
                <div className="button items-center justify-center" style={{ marginRight: 10 }} onClick={() => {
                    setShowHotEdit(true)
                    setContentSize(editContentSize)
                    setContent(editContent)
                    setSaveCb(onSave)
                    setDefaultValue(defaultValue)
                    setContentFit(contentFit)
                }}>展开画布</div>
                {
                    showCopyBtn === true && <div className="button items-center justify-center" onClick={onCopy}>复制第一组热区</div>
                }
            </div>

        </div>
    )
}