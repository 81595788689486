// eslint-disabled
import React, { useRef, useState, useLayoutEffect, useCallback, useMemo } from 'react'

export default function InfinityScroll({
    windowSize = {
        width: 0,
        height: 0,
    },
    children,
    speed = 0,
    direction = 'left'
}) {
    children = React.Children.toArray(children)
    const childrenWrapRef = useRef(null)
    const startPosX = useRef(0)
    const endPosX = useRef(windowSize.width)
    const prevEndPosX = useRef(0)
    const scrollDist = useRef(0)
    const scrollRef = useRef(null)
    const isMouseDown = useRef(false)
    const mouseDownPos = useRef({ x: 0, y: 0 })
    const scrollLen = 4
    const allChildrenList = useMemo(() => {
        const result = []
        for (let i = 0; i < scrollLen; i++) {
            result.push(...children)
        }
        return result
    }, [])
    const interval = useRef(null)

    const wrapStyle = Object.assign({
        display: 'flex',
    }, windowSize)

    const setWrapTransformX = useCallback((dist) => {
        childrenWrapRef.current.style.transform = `translateX(-${dist}px)`
    }, [childrenWrapRef])

    const startAutoScroll = useCallback(() => {
        endScroll()
        interval.current = setInterval(() => {
            scrollDist.current += direction === 'left' ? 1 : -1
            if (scrollDist.current >= endPosX.current || scrollDist.current <= prevEndPosX.current) {
                scrollDist.current = startPosX.current
            }

            setWrapTransformX(scrollDist.current)
        }, 60 - speed)
    }, [endPosX, startPosX, interval])

    const endScroll = () => {
        clearInterval(interval.current)
    }

    const dragEnd = (e) => {
        if (isMouseDown.current === false) return
        isMouseDown.current = false
        scrollDist.current += mouseDownPos.current.x - e.clientX
        if (scrollDist.current >= endPosX.current) {
            scrollDist.current = scrollDist.current - endPosX.current + startPosX.current
        } else if (scrollDist.current <= prevEndPosX.current) {
            scrollDist.current = startPosX.current + scrollDist.current
        }
        startAutoScroll()
    }

    useLayoutEffect(() => {
        const childrenListWidth = childrenWrapRef.current.offsetWidth / scrollLen
        startPosX.current = childrenListWidth * (Math.floor(scrollLen / 2))
        endPosX.current = childrenListWidth * (1 + Math.floor(scrollLen / 2))
        prevEndPosX.current = childrenListWidth * (Math.floor(scrollLen / 2) - 1)
        scrollDist.current = startPosX.current
        setWrapTransformX(startPosX.current)
        startAutoScroll()
        return endScroll
    }, [windowSize])
    return (
        <div
            style={wrapStyle}
            ref={scrollRef}
            onMouseDown={(e) => {
                isMouseDown.current = true
                mouseDownPos.current = {
                    x: e.clientX,
                }
                endScroll()
            }}
            onMouseMove={(e) => {
                if (isMouseDown.current === true) {
                    setWrapTransformX(scrollDist.current - e.clientX + mouseDownPos.current.x)
                }
            }}

            onMouseUp={dragEnd}
            onMouseLeave={dragEnd}
        >
            <div ref={childrenWrapRef}
                style={{
                    display: 'flex',
                    flexShrink: 0,
                }}
                draggable={false}
            >
                {
                    allChildrenList.map((child, index) => React.cloneElement(child, { key: index + 'infinity-scroll' }))
                }
            </div>

        </div>
    )
}