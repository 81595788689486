import React, { useState } from'react'
import { Input } from '@/components'
import './index.less'

export default function InputWithImg(props) {
    const [showImg, setShowImg] = useState(false)
    return (
        <div
            className="input-with-img-wrap"
        >
            <Input
                {...props}
                onMouseEnter={() => {
                    setShowImg(true)
                }}
                onMouseLeave={() => {
                    setShowImg(false)
                }}
                onMouseOut={() => {
                    setShowImg(false)
                }}
            />
            {
                showImg && props.defaultValue && props.defaultValue.indexOf('.mp4') < 0 && <img src={props.defaultValue} alt="" />
            }
            {
                showImg && props.defaultValue && props.defaultValue.indexOf('.mp4') > -1 && <video muted src={props.defaultValue} autoPlay alt="" />
            }
        </div>
        
    )
}