import React, { useContext, useEffect } from 'react'
import { Layout } from 'antd'
import { useNavigate } from 'react-router-dom'
import { DrawPageStateContext, UserInfoContext, ModulesContext, AdvertProviderContext, GlobalStoreContext } from '@/context'
import LeftContent from './LeftContent'
import RightContent from './RightContent'
import DrawContent from './DrawContent'
import Navigator from './Navigator'
import HotAreaEditContent from './HotAreaEditContent'
import ExportAndImportAlert from './ExportAndImportAlert'
import { getUserInfoById } from '@/server/userManager'

const { Header, Sider, Content } = Layout

export default function MainPage() {
    const { showHotEdit, showExportAndImport } = useContext(DrawPageStateContext)
    const { isLogin, useLogin, setUserInfo, setIsLogin } = useContext(UserInfoContext)
    const { initAdvertConfig } = useContext(AdvertProviderContext)
    const { removeLimitTime } = useContext(GlobalStoreContext)
    const { initModules } = useContext(ModulesContext)
    const navigate = useNavigate()
    const { getSaveLoginInfo, logoff } = useLogin()
    useEffect(() => {
        const { userId, timestamp } = getSaveLoginInfo()
        // 距离上次登录超过24小时将返回登录页
        if (Date.now() - timestamp > removeLimitTime) {
            logoff()
            navigate('/', { replace: true })
            return
        }
        if (userId) {
            // 执行获取用户信息操作
            getUserInfoById(userId).then((res) => {
                if (res) {
                    setIsLogin(true)
                    setUserInfo(res)
                    initModules()
                } else {
                    logoff()
                }
            })
        } else if (isLogin === false) {
            navigate('/', { replace: true })
        }
    }, [])
    useEffect(() => {
        initAdvertConfig()
    }, [])
    return (
        <Layout style={{
            height: '100vh'
        }}>
            <Header
                style={{
                    backgroundColor: '#7dbcea',
                    height: 48,
                    padding: 0,
                }}
            >
                <Navigator />
            </Header>
            <Layout hasSider style={{ flex: 1, position: 'relative' }}>
                {showHotEdit && <HotAreaEditContent />}
                <Sider width="208">
                    <LeftContent />
                </Sider>
                <Content
                    style={{
                        position: 'relative',
                        backgroundColor: '#108ee9'
                    }}
                >
                    {showExportAndImport && <ExportAndImportAlert />}
                    
                    <DrawContent />
                </Content>
                <Sider
                    width="360"
                >
                    <RightContent />
                </Sider>
            </Layout>
        </Layout>
    )
}