import React, { useState, useEffect, useRef, useMemo } from 'react'
import CX from 'classnames'
import './index.less'

export default function SelectCustom({
    chooseList = ['A', 'B', 'C', 'D', 'E'],
    style = {},
    onClick = () => {},
    onDrop = () => {},
    currentIndex = 0,
    useDrag = true
}) {
    const [activeIndex, setActiveIndex] = useState(currentIndex)
    const wrapStyle = Object.assign({
        width: 320, height: 30
    }, style)
    useEffect(() => {
        setActiveIndex(currentIndex)
    }, [currentIndex])
    const selectElemsRef = useRef(chooseList.map(n => null))
    const isAnimating = useRef(false)
    const animateDuration = useMemo(() => 600, [])
    return (
        <div className="select-wrap" style={wrapStyle}>
            {
                chooseList.map((item, index) => {
                    return (
                        <div
                            key={'select' + index}
                            className={CX({
                                active: index === activeIndex,
                                normal: true,
                                'items-center': true,
                                'justify-center': true,
                            })}
                            ref={(ref) => {
                                selectElemsRef.current[index] = ref
                            }}
                            onClick={() => {
                                if (isAnimating.current === true) return
                                setActiveIndex(index)
                                onClick(index)
                            }}
                            onDragEnter={(e) => {
                                e.preventDefault()
                            }}
                            onDragOver={(e) => {
                                e.preventDefault()
                            }}
                            onDragStart={(e) => {
                                if (useDrag === false) {
                                    e.preventDefault()
                                    return
                                }
                                e.dataTransfer.setData('from', index)
                            }}
                            onDrop={(e) => {
                                if (isAnimating.current === true) return
                                isAnimating.current = false
                                setActiveIndex(-1) // 动画进行前先取消
                                const from = Number(e.dataTransfer.getData('from'))
                                const newChooseList = [...chooseList]
                                const newIndexGrp = []
                                const fromData = newChooseList.splice(from, 1)[0]
                                newChooseList.splice(index, 0, fromData) // 将移出的元素重新插入
                                newChooseList.forEach((item, index) => {
                                    newIndexGrp.push(chooseList.indexOf(item))
                                }) // 生成一组重新插入后的角标
                                const elemRect = selectElemsRef.current[0].getBoundingClientRect()
                                const elemWidth = elemRect.width

                                // 移动动画
                                selectElemsRef.current.forEach((elem, elemIndex) => {
                                    const i = newIndexGrp.indexOf(elemIndex)
                                    elem.style.transition = `transform ${animateDuration}ms`
                                    elem.style.transform = `translateX(${(i - elemIndex) * elemWidth}px)`
                                }) // 所有元素向前或者向后移动
                                setTimeout(() => {
                                    selectElemsRef.current.forEach((elem) => {
                                        elem.style.transition = '0ms'
                                    })
                                    setTimeout(() => {
                                        selectElemsRef.current.forEach((elem) => {
                                            elem.style.transform = `translateX(0)`
                                        })
                                        isAnimating.current = false
                                        const newActiveIndex = newIndexGrp.indexOf(activeIndex)
                                        setActiveIndex(newActiveIndex)
                                        onClick(newActiveIndex)
                                        onDrop(newIndexGrp, 0)
                                    }, 50)
                                    
                                }, animateDuration)
                            }}
                            draggable="true"
                        >{item}</div>
                    )
                })
            }
        </div>
    )
}