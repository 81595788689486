import React from 'react'
import HotArea from '../HotArea'

// TODO 后续修改为function comp
export default class CardSliderHotArea extends React.PureComponent {
    constructor(props) {
        super(props)
        this.banners = [...this.props.banners, ...this.props.banners, ...this.props.banners]
        this.realLen = this.props.banners.length
        this.elems = this.banners.map(b => null)
        this.isMouseDown = false
        this.mouseDownPosX = 0
        this.index = this.realLen
        this.animateTime = 300
    }

    scrollNext = () => {
        this.elems[this.index].style.transform = `translateX(-100%) scale(1)`
        this.index += 1
        for (let i = this.index; i < this.banners.length; i++) {
            const elem = this.elems[i]
            elem.style.transform = `translateX(${(i - this.index) * this.props.between}px) scaleY(${1 - (i - this.index) * 0.1})`
            elem.style.opacity = 1
        }
        for (let j = 0; j < this.index; j++) {
            const elem = this.elems[j]
            elem.style.transition = `${this.animateTime}m`
            elem.style.opacity = 1
        }
        setTimeout(() => {
            if (this.index === this.realLen * 2) {
                this.init()
            }
            this.props.onChange(this.index % this.props.banners.length)
        }, this.animateTime)
    }

    scrollPrev = () => {
        this.index -= 1
        for (let i = this.index; i < this.banners.length; i++) {
            const elem = this.elems[i]
            elem.style.transform = `translateX(${(i - this.index) * this.props.between}px) scaleY(${1 - (i - this.index) * 0.1})`
            elem.style.opacity = 1
        }
        for (let j = 0; j < this.index; j++) {
            const elem = this.elems[j]
            elem.style.transition = `${this.animateTime}m`
            elem.style.opacity = 1
        }
        setTimeout(() => {
            if (this.index === this.realLen * 2) {
                this.init()
            }
            this.props.onChange(this.index % this.props.banners.length)
        }, this.animateTime)
    }

    init = () => {
        const { between } = this.props
        for (let i = 0; i < this.banners.length; i++) {
            this.index = this.realLen
            const elem = this.elems[i]
            elem.style.transition = '0ms'
            if (i < this.realLen) {
                elem.style.transform = `translateX(-100%) scale(1)`
            } else {
                const _i = i - this.realLen
                elem.style.transform = `translateX(${_i * between}px) scaleY(${1 - _i * 0.1})`
            }
            setTimeout(() => {
                elem.style.transition = `${this.animateTime}ms`
            }, 30)
        }
    }

    startAutoScroll = () => {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
            this.scrollNext()
        }, this.props.interval * 1000)
    }

    componentDidMount() {
        this.init()
        this.startAutoScroll()
    }

    stopAutoScroll = () => {
        clearInterval(this.timer)
    }

    render() {
        const {
            wrapWidth,
            bannerRect,
            between,
        } = this.props
        const { width, height } = bannerRect
        return (
            <div
                style={{
                    position: 'relative',
                    width: wrapWidth,
                    height,
                    overflow: 'hidden',
                }}
                onMouseDown={(e) => {
                    this.stopAutoScroll()
                    this.mouseDownPosX = e.clientX
                    this.isMouseDown = true
                }}
                onMouseUp={(e) => {
                    if (this.isMouseDown === false) return
                    if (e.clientX - this.mouseDownPosX > 5) {
                        this.scrollPrev()
                    } else if (e.clientX - this.mouseDownPosX < -5) {
                        this.scrollNext()
                    }
                    this.isMouseDown = false
                    this.startAutoScroll()
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        width: width + (this.realLen >= 3 ? 2 * between : (this.realLen - 1) * between),
                        overflow: 'hidden',
                        height,
                    }}
                >
                    {
                        this.banners.map((banner, index) => {
                            return (
                                <div
                                    style={{
                                        position: 'absolute',
                                        width,
                                        height,
                                        zIndex: this.banners.length - index
                                    }}
                                    ref={(r) => this.elems[index] = r}
                                    key={index}
                                    draggable={false}
                                >
                                    <img
                                        src={banner.imageUrl}
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        draggable={false}
                                    />
                                    {
                                        banner.hotArea.map((hot, hotIndex) => {
                                            return <HotArea key={hotIndex} hot={hot} />
                                        })
                                    }
                                </div>
                            )

                        })
                    }
                </div>

            </div>

        )
    }
}