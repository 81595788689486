import React, { createContext, useState } from 'react'
import { getAdvert } from '@/server/advert'
export const AdvertProviderContext = createContext({})

// 广告数据管理
const AdvertProvider = ({ children }) => {                          
    const [bannerAdvertInfo, setBannerAdvertInfo] = useState({      //顶部放大缩小的广告数据
        imgUrl: '',
        jumpUrl: '',
        height: '',
    })
    const [caseAdvertInfo, setCaseAdvertInfo] = useState([])        
    return (
        <AdvertProviderContext.Provider
            value={{
                caseAdvertInfo,
                bannerAdvertInfo,
                initAdvertConfig: () => {
                    getAdvert().then((res) => {
                        setBannerAdvertInfo({
                            imgUrl: res.bannerImgUrl,
                            jumpUrl: res.bannerJumpUrl,
                            height: res.bannerHeight,
                        })
                        setCaseAdvertInfo(res.caseImgUrlList.map((n, i) => {
                            return {
                                imgUrl: n,
                                expandUrl: '1',
                            }
                        }))
                    })
                }
            }}
        >

            {children}
        </AdvertProviderContext.Provider>
    )
}

export default AdvertProvider