import React, { useRef, useEffect, useCallback } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { takeNumberFromStr } from '@/utils'
import './index.less'

export default function InputNumber({
    labelContent = '高',
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    style,
    defaultValue = 0,
    step = 1,
    max = 1000,
    min = 0
}) {
    const inputRef = useRef(null)
    const isFocus = useRef(false)
    const wrapStyle = Object.assign({}, style)
    const setInputValue = useCallback((v) => {
        inputRef.current.value = v
    }, [defaultValue])

    useEffect(() => {
        if (isFocus.current === false) {
            setInputValue(defaultValue)
        }
    }, [defaultValue])

    return (
        <div
            style={wrapStyle}
            className="input-number-bg flex-row items-center justify-between"
        >
            <input
                ref={inputRef}
                onFocus={() => {
                    isFocus.current = true
                    onFocus()
                }}
                onBlur={() => {
                    isFocus.current = false
                    onBlur()
                }}
                type="tel" 
                onChange={(e) => {
                    // onChange(Math.min(takeNumberFromStr(e.target.value), max))
                    onChange(takeNumberFromStr(e.target.value))
                }}
            />
            <div className="items-center">
                <label>{labelContent}</label>
                <div className="arrow flex-col">
                    <div
                        className="justify-center items-center"
                        onClick={() => {
                            // const result = Math.min(takeNumberFromStr(inputRef.current.value) + 1, max * 3)
                            const result = (takeNumberFromStr(inputRef.current.value) + step).toFixed(2)
                            inputRef.current.focus()
                            inputRef.current.value = result
                            onChange(result)
                        }}
                    >
                        <DownOutlined style={{
                            width: 5,
                            height: 3,
                        }}/>
                    </div>
                    <div
                        className="justify-center items-center"
                        onClick={() => {
                            const result = (takeNumberFromStr(inputRef.current.value) - step).toFixed(2)
                            inputRef.current.focus()
                            inputRef.current.value = result < 0 ? 0 : result
                            onChange(result)
                        }}
                    >
                        <DownOutlined style={{
                            width: 5,
                            height: 3,
                        }}/>
                    </div>
                </div>
            </div>
            
        </div>
    )
}